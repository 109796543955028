
import { defineComponent, onUnmounted } from "vue";
import { useRoute, useWindownSize } from "@/helpers";
import { get } from "lodash";

export default defineComponent({
  setup() {
    const { vw, vh, remove } = useWindownSize();
    const { query, back } = useRoute();
    const link = get(query, "link", "");
    if (!link) back();
    onUnmounted(() => {
      remove();
    });
    // console.log("setup -> query", query);
    return { vw, vh, back, link };
  }
});
