import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ height: _ctx.vh + 'px', width: _ctx.vw + 'px' }),
    class: "image-view"
  }, [
    _createElementVNode("i", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args))),
      class: "far fa-times-circle"
    }),
    _createElementVNode("img", {
      class: "image-view-image",
      src: _ctx.link,
      alt: ""
    }, null, 8, _hoisted_1)
  ], 4))
}